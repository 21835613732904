import "./Contact.css";
import React from "react";
import { TextField, Button, Grid } from '@mui/material';
import { Formik, Form } from "formik";
import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const schema = Yup.object().shape({
  name:
    Yup.string()
      .required('Name is required')
      .min(3),
  email:
    Yup.string()
      .required('Email is required')
      .email('Please enter a valid email'),
  phone:
    Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Phone number is required'),
  message:
    Yup.string()
      .required('Message number is required')
      .min(50, 'please enter a message of 50 chars at least'),

})

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}


function template() {
  return (
    <div className="contact">
      <h1>Contact</h1>
      <Formik
        initialValues={{
          name: '',
          email: '',
          phone: '',
          message: ''
        }}
        validationSchema={schema}
        onSubmit={(values, actions) => {
          fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact-demo", ...values })
          })
            .then(() => {
              alert('Success');
              actions.resetForm()
            })
            .catch(() => {
              alert('Error');
            })
            .finally(() => actions.setSubmitting(false))
        }
        }
      >
        {formik => {
          return <Form name="contact-us" data-netlify={true}>
            <Grid container spacing={2} >

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="phone"
                  label="Phone"
                  name="phone"
                  value={formik.values.phone}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="message"
                  label="Message"
                  name="message"
                  multiline
                  rows={4}
                  value={formik.values.message}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.message && Boolean(formik.errors.message)}
                  helperText={formik.touched.message && formik.errors.message}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  disabled={!formik.isValid}
                  type="submit">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        }}
      </Formik>
    </div >
  );
};

export default template;
