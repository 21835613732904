import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import logo from '../../_global/logo.png';
import menu from './menu.json';
import { alpha, Container } from '@mui/material';
import { mobile_breakpoint } from '../../_global/constants';


function Header(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <img src={logo}  alt="PALEQ Logo" width={75} height={53} />
      </Typography>
      <Divider />
      <List>
        {menu.map((item) => (
          <NavLink 
            key={item.id}
            to={item.to}
          >
            <ListItem 
                disablePadding 
                sx={{ 
                  color: '#fff'}}
              >
                <ListItemButton 
                  sx={{ textAlign: 'center' }}>
                    <ListItemText primary={item.name} />
                </ListItemButton>
            </ListItem>
          </NavLink>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{mb: 15}}>
      <CssBaseline />
      <AppBar component="nav" style={{ background: alpha('#000', 0.5 ), boxShadow: 'none'}}>
        <Toolbar>
          <Container maxWidth="md" sx={{display: 'flex', alignItems: 'center'}}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, my: 2 }}
            >
              <img src={logo}  alt="PALEQ Logo" width={75} height={53} />
            </Typography>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              {menu.map((item) => (
                <Button 
                  key={item.id} 
                  component={NavLink} to={item.to}
                  sx={{ '&.active': { textDecoration:'underline', color: 'white'}}}
                  >
                  {item.name}
                </Button>
              ))}
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: mobile_breakpoint },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default Header;
