import React, { useEffect, useState }  from 'react';
import styles from './Team.module.scss';
import { Card, CardContent, CardHeader, CardMedia, Grid } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import API from '../../Services/API';

import PageTitle from '../../components/page-title/page-title';

const Team = () => {

  const [team, setTeam] = useState([])

  useEffect(() => {
    const api = new API('team')
    let mounted = true
    api.render().then(data => mounted && setTeam(data))
    return () => { mounted = false }
  },[])


  return(<section className={styles.Team}>
    <PageTitle title="Team" />
    <Grid container spacing={2} columns={12}>
    
    {team.map(i => (
          <Grid item xs={12} sm={4} key={i.id} >
            <Card className={styles.singleMember}>
              <CardHeader
                title={i.name}
                subheader={i.title}
              />
              <CardMedia
                component="img"
                height="194"
                image={i.image.fields.file.url}
                alt={i.title}
              />
              <CardContent className={styles.SocialLinks}>
                {i.linkedin && 
                <a 
                    href={i.linkedin} 
                    target="_blank" 
                    title="LinkedIn"
                    rel='noreferrer'
                >
                    <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
                }

                {i.twitter && 
                <a 
                    href={i.twitter} 
                    target="_blank" 
                    title="LinkedIn"
                    rel='noreferrer'
                >
                    <FontAwesomeIcon icon={faTwitter} />
                </a>
                }


                {i.email && 
                <a 
                    href={`mailto:${i.email}`} 
                    target="_blank" 
                    title="LinkedIn"
                    rel='noreferrer'
                >
                    <FontAwesomeIcon icon={faEnvelope} />
                </a>
                }
              </CardContent>
            </Card>
          </Grid>
      ))}
  </Grid>
  </section>)
};

export default Team;
