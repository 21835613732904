import React, { useEffect, useState } from 'react';
import styles from './list-of-services.module.scss';
import { Avatar, Box, Typography } from '@mui/material';
import API from '../../Services/API';
import getImage from '../../Utils/getImage';
import { NavLink } from 'react-router-dom';

const ListOfServices = () => {
  const [loaded , setLoaded] = useState(false);
  const [posts, setPosts] = useState();

  useEffect(() => {
    const api = new API('services')
    api.render().then(data => {
      setLoaded(true)
      setPosts(data)
    })
  },[])
  
  return <Box display='flex' alignItems='center' justifyContent='center' mt={5}>
  { (loaded) ? 
    posts.map(post => <NavLink 
      className={styles.ServiceLink} 
      key={post.id}
      to={`/services/${post.slug}`}>
      <Box 
       
        display="flex"
        mx={2}
        alignItems='center'
        flexDirection='column'
      >
      
        <Avatar className={styles.AvatarGrayScale} alt={post.title} src={`https:${getImage(post.thumbnail)}`} />
        <Typography className={styles.ServiceTitle} mb={3} variant="h6" component="h1" textAlign={'center'}>
          {post.title}
        </Typography>
      </Box>
    </NavLink>)
  : ''}
  
  </Box>

};

export default ListOfServices;
