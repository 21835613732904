import "./Portfolio.css";
import React from "react";
import PageTitle from '../../components/page-title/page-title';
import ImageListItem from '@mui/material/ImageListItem';
import getImage from "../../Utils/getImage";

import ImageListItemBar from '@mui/material/ImageListItemBar';
import { Grid } from "@mui/material";

function template() {
  return (
    <section className="portfolio">
      <PageTitle
        title="Portfolio"
        subtitle="Our Top Selected projects that we are proud of and happy to share with you." />

      <Grid container spacing={3}>
        {this.state.portfolio.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <ImageListItem>
              <img
                src={`https:${getImage(item.image)}`}
                srcSet={`https:${getImage(item.image)} 2x`}
                alt={item.company}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.company}
                subtitle={item.url}
              />
            </ImageListItem>
          </Grid>
        ))}
      </Grid>
    </section>
  );
};

export default template;
