import "./Home.css";
import { Box, Typography } from "@mui/material";
import WhatWeDoImage from '../../assets/images/business-team-discussing-their-ideas-while-working-office.webp'
import Testimonials from '../../components/Testimonials/Testimonials.lazy';

function template() {
  return (
    <main className="fullWidth">
      <>
        <section className="hero">
          <Box>
            <Typography variant="h6" textAlign={'center'}>
              <span className="highlighted">paleq</span> Business Strategy For Guaranteed Success.
            </Typography>
            <Typography variant="h3" textAlign={'center'}>
              Helping Business And Companies Innovate Transform And Lead
            </Typography>
          </Box>

        </section>
        <Box style={{ backgroundColor: 'white', color: 'black' }} py={15} sx={{ display: { xs: 'block', md: 'flex' }, paddingX: { xs: 10, lg: 50 } }}>

          <Box sx={{ width: { xs: '100%', md: '50%' } }}>
            <img src={WhatWeDoImage} alt='What We Do' width={'100%'} />
          </Box>

          <Box sx={{ width: { xs: '100%', md: '50%' }, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', px: { md: 3, xl: 5 } }}>
            <Typography variant="h2">
              What we do
            </Typography>
            <Typography variant="p">
              Team paleq helps you bring on your advertising and marketing A-game through its diversified skillset. Creating the perfect blend of creativity, technology, and strategy so that no stone is left unturned in making your brand a huge success.
            </Typography>
          </Box>
        </Box>
        <Testimonials />
      </>
    </main>
  );
};

export default template;
