import "./App.scss";
import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from '../components/Header/Header';

import Home from '../pages/Home/Home';
import Portfolio from '../pages/Portfolio/Portfolio';
import Team from '../pages/Team/Team';
import Contact from '../pages/Contact/Contact';
import NotFound from '../components/NotFound/NotFound';
import { Container } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import Services from "../pages/Services";
import SingleServicePage from '../pages/SingleServicePage/SingleServicePage';




function template() {
  return (
    <ThemeProvider theme={this.state.theme}>
      <Container maxWidth="md">
        <BrowserRouter>
          <Header />
          <Routes>
            <Route index element={<Home />} />
            <Route path="portfolio" element={<Portfolio />} />
            <Route path="team" element={<Team />} />

            <Route path="services">
              <Route index={true} element={<Services />} />
              <Route path=":slug" element={<SingleServicePage forceRefresh={true} />} />
            </Route>

            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </Container>
    </ThemeProvider >
  );
};

export default template;
