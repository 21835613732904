import React from 'react';
import styles from './page-title.module.scss';
import { Typography } from '@mui/material';

const PageTitle = ({ title, subtitle = null }) => (
  <section className={styles.PageTitleHeader}>
    <Typography mb={3} variant="h5" component="h2">
      {title}
    </Typography>
    {subtitle && <Typography mb={5} variant="h6" className={styles.PageSubtitle} component="h5">
      {subtitle}
    </Typography>}
  </section>

);

export default PageTitle;
