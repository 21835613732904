import React from 'react';
import styles from './SinglePostCard.module.scss';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import { Grid } from '@mui/material';


const SinglePostCard = props => {
  const post = props.post
  
  const title = post.title
  const img = (post.thumbnail) ? post.thumbnail.fields.file.url : 'http://images.ctfassets.net/wsevzuqlaksy/6Uyto4ILQbH2y9yg9HXsaB/dd6464828c0c4d4d9b7e973bc24d0c0b/default-fallback-image.webp'
  const img_alt = post.title
  const excerpt = post.excerpt
  const slug = post.slug
  const mainSlug = (props.mainSlug) ? props.mainSlug : 'blog' ;
  return (
    <Grid item xs={12} sm={6} md={4}>
      <NavLink to={`/${mainSlug}/${slug}`}>
        <Card className={styles.SinglePostCard} >
          <CardMedia
            component="img"
            height="194"
            image={img}
            alt={img_alt}
          />
          <CardContent>
            <Typography variant="h5" component="div">
            {title} 
            </Typography>
            <Typography dangerouslySetInnerHTML={{ __html: excerpt }} variant="body2" color="text.secondary">
            </Typography>
            <Button size="small">Learn More</Button>
          </CardContent>
        </Card>
      </NavLink>
    </Grid>
)
};

export default SinglePostCard;
