import "./SingleServicePage.css";
import React from "react";
import { CircularProgress } from '@mui/material';
import { Markup } from 'interweave';
import PageHero from "../../components/page-hero/page-hero";
import getImage from "../../Utils/getImage";
import ListOfServices from "../../components/list-of-services/list-of-services";


function template() {
  return (
    <div className="single-service-page">
      {
        this.state.loaded
          ?
          <>
            <PageHero
              image={`https:${getImage(this.state.post.featuredImage)}`}
              title={this.state.post.title} />
            <Markup content={this.state.post.body} />
            <ListOfServices />
          </>
          : <CircularProgress />
      }
    </div>
  );
};

export default template;
