import React from 'react';
import styles from './page-hero.module.scss';
import { Typography } from '@mui/material';

const PageHero = ({ title, subtitle = '', image = 'https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png' }) => (
  <>
  <section 
    className={styles.PageHeroHeader}
    style={{backgroundImage: `url(${image})`}}  
  >
    <Typography mb={3} variant="h2" component="h1">
      {title}
    </Typography>
    {subtitle && <Typography mb={5} variant="h6" className={styles.PageSubtitle} component="h5">
      {subtitle}
    </Typography>}
  </section>
  <article className={styles.PageHeroHeaderFixer}>

  </article>
  </>

);

export default PageHero;
