import React from "react";
import template from "./Services.jsx";
import API from '../../Services/API';

class Services extends React.Component {

  constructor(){
    super()

    this.state = {
      services: []
    }
  }

  componentDidMount(){
    const api = new API('services')

    api.render()
    .then(data => this.setState({services: data}))
  }


  render() {
    return template.call(this);
  }
}

export default Services;
