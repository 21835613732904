import { client } from './contentful'

class API{
    constructor(type = 'blog', field = null, id = null){
        this.type = type
        this.field = field
        this.id = id
    }

    async render(){
        if(!this.saved_locally()){
            //Not saved, retrieve new one
            try{
                return this.fetch()
            }catch(err){
                console.error(err)
            }
        }else{
            return this.saved_locally()
        }
    }

    saved_locally(){

        const cached = JSON.parse(localStorage.getItem(`cached_${this.type}_filter_${this.filter}_id_${this.id}`))

        if(!cached ||
        Math.abs(new Date() - new Date(cached.time)) > 86400000){
            //Cache not found or more than one day
            return false;
        }

        return cached.posts
    }

    
    async fetch(){
        try{
            const posts = await client.getEntries({
                content_type: this.type,
                select: "fields"
            })

            const sanitized_posts = posts.items.map(item => {
                const image = item.fields.image
                const id = item.sys.id
                
                return {
                    id,
                    ...item.fields,
                    image
                }
            })
            

            const caching = {posts: sanitized_posts, time: new Date() };
            localStorage.setItem(`cached_${this.type}_filter_${this.filter}_id_${this.id}`, JSON.stringify(caching))

            return sanitized_posts;
        }catch (err){
            console.log(err)
        }
    }

    async single(slug){
        let posts =  await this.saved_locally()
        let single = (posts) ? posts.filter(post=> post.slug === slug) : []
        
        // if not found, fetch again 
        if(single.length ===0){
            posts = this.fetch()
            .then(posts => {
                single = posts.filter(post=> post.slug === slug)

                // if not found on fetch, return false
                if(single.length === 0){
                    return false
                }
                return single[0]
            })
        }else{
            return single[0]
        }        
    }
}


export default API;