import React from "react";
import template from "./Portfolio.jsx";
import API from '../../Services/API';

class Portfolio extends React.Component {

  constructor(){
    super()

    this.state = {
      portfolio: []
    }
  }

  componentDidMount(){
    const api = new API('portfolio')

    api.render()
    .then(data => this.setState({portfolio: data}))
  }


  render() {
    return template.call(this);
  }
}

export default Portfolio;
