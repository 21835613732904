import "./Services.css";
import React from "react";

import { CircularProgress, Grid } from '@mui/material';

import SinglePostCard from '../../components/SinglePostCard/SinglePostCard';
import PageTitle from '../../components/page-title/page-title';

function template() {
  return (
    <div className="services">
      <PageTitle title="Services" />
      {this.state.services
        ?
        <Grid container spacing={3}>
          {this.state.services.map(post => <SinglePostCard mainSlug='services' key={post.id} post={post} />)}
        </Grid>
        : <CircularProgress />

      }
    </div>
  );
};

export default template;
