import React    from "react";
import template from "./SingleServicePage.jsx";
import API from '../../Services/API';
import { useParams } from 'react-router-dom';


export function withRouter(Children){
  return(props)=>{
     const match  = {params: useParams()};
     return <Children {...props}  match = {match}/>
 }
}

class SingleServicePage extends React.Component {
  constructor(){
    super()

    this.state = {
      post: {},
      loaded: false
    }
  }

  loadContent(slug){
    const api = new API('services')
    
    
    api.single(slug)
    .then(post => this.setState({post, loaded: true}))    
  }

  componentDidMount(){
    const { slug } = this.props.match.params
    this.loadContent(slug)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      const { slug } = this.props.match.params
      this.loadContent(slug)
    }
  }

  render() {
    return template.call(this);
  }
}

export default withRouter(SingleServicePage);
