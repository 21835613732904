import React    from "react";
import template from "./Contact.jsx";

class Contact extends React.Component {
  constructor(){
    super();

    this.state = {
      errors: {}
    }
  }
  
  render() {
    return template.call(this);
  }
}

export default Contact;
