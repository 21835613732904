import React from "react";
import template from "./App.jsx";

import { createTheme } from "@mui/material";

class App extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      theme: {}
    }
  }

  componentDidMount(){
    this.setState({
      theme: createTheme({
        palette: {
          mode: 'dark',
          primary: {
            main: '#DAA520',
          },
          secondary: {
            main: '#D0D',
          },
        },
      })
    });
  }



  render() {
    return template.call(this);
  }
}

export default App;
